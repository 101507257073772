import { useContext, useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import { NotificationContext, store, UserContext } from '@mdc/ui';
import { LocationService, StoreService, QueryParams, Authenticator, BackendService, logService, GtmService } from '@mdc/services';
import { STRIPE, NOTIFICATION_TYPES } from '@mdc/constants';

import faqData from '../../components/store/faqs.data';
import { useTranslation } from 'react-i18next';

const { Store, ThankYou } = store;

const SUCCESS_ID = 'SUCCESS_ID';

const useLocalCurrency = () => {
    const [currency, setCurrency] = useState('usd');
    useEffect(() => {
        (async () => {
            if ((process.env.ENVIRONMENT !== 'local')) {
                const response = await LocationService.getLocation();
                const moneyType = StoreService.getCurrency(response.countryName);
                setCurrency(moneyType);
            }
        })();
    }, []);
    return currency;
};

const useRestrictCountry = () => {
    const [allowAccess, setAllowAccess] = useState(true);
    useEffect(() => {
        (async () => {
            if ((process.env.ENVIRONMENT !== 'local')) {

                const response = await LocationService.getLocation();
                // COMMENT line above and UNCOMMENT line below for local testing
                // const countryCode = 'SS';
                const verifyBlockedCountry = StoreService.verifyBlockedCountry(response.countryName);

                if (verifyBlockedCountry) {
                    setAllowAccess(false);
                }
            }
        })();
    }, []);
    return allowAccess;
};


const StoreTemplate = () => {
    const { t } = useTranslation();
    const { session_id: success } = QueryParams();

    const countryValidator = useRestrictCountry();

    const { notify } = useContext(NotificationContext);
    const userContext = useContext(UserContext);

    const userData = useMemo(() => userContext?.data && {
        ...userContext.data,
        expirationDate: new Date(userContext.data.expiration_date)
    }, [userContext]);

    const [planType, setPlanType] = useState(STRIPE.PLAN_TYPE.MONTHLY);
    const [subscriptionsHistory, setSubscriptionsHistory] = useState();
    const [subscriptions, setSubscriptions] = useState(undefined);
    const [expirationDate, setExpirationDate] = useState(Date.now());
    const [hasSubscriptions, setHasSubscriptions] = useState(false);
    const [subscriptionCurrency, setSubscriptionCurrency] = useState(undefined);
    const [emailValidator, setEmailValidator] = useState(true);
    const localCurrency = useLocalCurrency();
    const currency = useMemo(() => subscriptionCurrency || localCurrency, [subscriptionCurrency, localCurrency]);

    const [isPaymentPending, setIsPaymentPending] = useState(false);
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
    const [products, setProducts] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [numberOfEngines, setNumberOfEngines] = useState('20+');

    const uiData = {
        'Prevention API 2021': {
            shortName: 'MetaDefender Prevention API',
            className: 'prevention',
            // eslint-disable-next-line no-useless-escape
            description: t('<div>The MetaDefender Cloud Prevention API includes:<ul><li>Scanning files with 10 traditional and next-gen anti-malware engines</li><li>Preventing 0-day attacks by productivity documents using Deep CDR</li></ul></div>', { commercialEnginesNo: numberOfEngines })
        },
        'MetaDefender Cloud Analysis API': {
            shortName: 'Metadefender Cloud Sandbox API',
            className: 'analysis',
            description: t('<div>The MetaDefender Cloud Sandbox API includes access to our Sandbox Technology:<ul><li>Extract Indicators of Compromise (IOCs) from a wide range of executables, documents, scripts, and URLs</li><li>Emulates 90%+ of highly obfuscated state-of-the-art macro malware (VBA), VBS, PowerShell, Jscript, MSHTA, XSL, WSF</li><li>Rapid & deep analysis at high scale</li><li>REST API for automated integration</li><li>Integrates with YARA, MITRE ATT&CK framework and more</li><li>Designed, engineered, and maintained by experienced industry experts</li></ul></div>')
        },
        'Reputation API 2021': {
            shortName: 'Metadefender  Reputation API',
            className: 'reputation',
            description: t('<div>The MetaDefender Reputation Service includes:<ul><li>Access to billions of malware and hashes via REST API</li><li>Scanning IP addresses, URLs and domains using up to 30 different engines</li><li>Correlating hashes to millions of known applications and providing specific application data from vendors like Microsoft, Adobe, and others</li></ul></div>')
        },
        'Threat Intelligence Search API': {
            shortName: 'Metadefender Thread Intelligence API',
            className: 'reputation',
            description: t('<div>The MetaDefender Threat Intelligence API includes:<ul><li>Pattern search (expression search) intelligence data that focuses on finding exact matches of predefined expressions such as malware families, malware threat names, AV detection filtering, first/last seen, etc</li><li>Similarity search techniques aim to identify patterns or indicators that bear resemblance to known threats or Indicators of Compromise (IOCs), even in cases where they may not be exact matches</li></ul></div>')
        },
    };

    const isOrganization = useMemo(() => userData?.organization && userData.organization['organization_id'] !== undefined, [userData]);

    useEffect(() => {
        if (userContext.state !== userContext.STATES.PENDING) {
            (async () => {
                try {
                    const { products, currencySymbol } = await StoreService.getStoreData(currency, uiData);
                    if (!products || !currencySymbol) {
                        notify({
                            message: t('Failed to load the products. Please try again!'),
                            type: NOTIFICATION_TYPES.WARNING
                        });
                    } else {
                        setProducts(products);
                        setCurrencySymbol(currencySymbol);

                    }
                } catch (error) {
                    notify({
                        message: t('Failed to load the products. Please try again!'),
                        type: NOTIFICATION_TYPES.WARNING
                    });
                }
            })();
        }
    }, [currency, userContext, t]);

    useEffect(() => {
        (async () => {
            const enginesNumber = await StoreService.getEnginesNumber();

            if (enginesNumber) {
                setNumberOfEngines(enginesNumber);
            }
        })();
    }, []);

    useEffect(() => {
        if (userData && userContext.state === userContext.STATES.LOGGED_IN) {
            (async () => {
                const data = await BackendService.emailValidator();
                if (data && data.status === 'Not eligible') {
                    setEmailValidator(false);
                }
            })();
        }
    }, [userData, userContext]);

    useEffect(() => {
        if (!userData || userContext.state !== userContext.STATES.LOGGED_IN) {
            return;
        }

        const fetchData = async () => {
            const allPlans = await fetchAllPlans();
            const { latestSubscriptions, isPaymentCompleted, isPaymentPending } = await fetchLatestSubscriptions();

            if (isPaymentCompleted || isPaymentPending) {
                updatePaymentStatus(isPaymentCompleted, isPaymentPending);
            }

            if (!latestSubscriptions) {
                return;
            }

            const { matchedPlans, planKind, endDate } = matchPlansWithSubscriptions(latestSubscriptions, allPlans, userData.expirationDate);
            updateSubscriptionDetails(matchedPlans, planKind, endDate);
        };

        fetchData();
    }, [userData, userContext]);

    const fetchAllPlans = async () => {
        return (await StoreService.getAllProducts())
            .reduce((plans, product) => [...plans, product.plans], [])
            .map((plans) => Object.keys(plans).map((moneyType) => plans[moneyType].map((plan) => ({ ...plan, moneyType }))))
            .map((plans) => plans.reduce((all, currencyPlans) => [...all, ...currencyPlans], []))
            .reduce((final, plans) => [...final, ...plans], []);
    };

    const fetchLatestSubscriptions = async () => {
        let latestSubscriptions = subscriptionsHistory;
        let isPaymentCompleted = false;
        let isPaymentPending = false;

        if (!latestSubscriptions) {
            latestSubscriptions = (await StoreService.getSubscription())[0];
            setSubscriptionsHistory(latestSubscriptions);
        }

        if (latestSubscriptions && latestSubscriptions.length) {
            const subscriptionActive = checkSubscriptionActive(latestSubscriptions[0]);
            isPaymentCompleted = subscriptionActive.isPaymentCompleted;
            isPaymentPending = subscriptionActive.isPaymentPending;
        }

        return { latestSubscriptions, isPaymentCompleted, isPaymentPending };
    };

    const checkSubscriptionActive = (subscription) => {
        const subscriptionDate = new Date(subscription.expirationDate).setHours(23, 59, 59, 999);
        const isPaymentCompleted = subscription.invoiceUrl && subscription.receiptUrl && subscriptionDate > Date.now() && !subscription.canceledAt;
        const isPaymentPending = subscription.invoiceUrl && !subscription.receiptUrl;

        return { isPaymentCompleted, isPaymentPending };
    };

    const matchPlansWithSubscriptions = (latestSubscriptions, allPlans, userDataExpirationDate) => {
        let endDate = userDataExpirationDate?.getTime();
        let matchedPlans = [];
        let planKind = STRIPE.PLAN_TYPE.MONTHLY;

        if (latestSubscriptions && latestSubscriptions.length) {
            const subscription = latestSubscriptions[0];
            const subscriptionDate = new Date(subscription.expirationDate).setHours(23, 59, 59, 999);

            if (subscriptionDate > endDate) {
                endDate = subscriptionDate;
            }

            const subsId = latestSubscriptions.map(sub => sub.tier.toLowerCase().replace(/\s/g, '_') + `_${sub.currency}`);
            matchedPlans = allPlans.filter(plan => subsId.includes(plan.id));
            planKind = subscription.type.toLowerCase().includes('month') ? STRIPE.PLAN_TYPE.MONTHLY : STRIPE.PLAN_TYPE.ANNUAL;
        }

        return { matchedPlans, planKind, endDate };
    };

    const updatePaymentStatus = (isPaymentCompleted, isPaymentPending) => {
        if (isPaymentCompleted) {
            setIsPaymentCompleted(true);
        } else if (isPaymentPending) {
            setIsPaymentPending(true);
        }
    };

    const updateSubscriptionDetails = (matchedPlans, planKind, endDate) => {
        if (endDate) {
            setExpirationDate(endDate);
        }

        if (matchedPlans?.length) {
            setPlanType(planKind);
            setSubscriptions(matchedPlans);
            setSubscriptionCurrency(matchedPlans[0].currency);
        }

        setHasSubscriptions(endDate > Date.now());
    };


    const faqContent = faqData(t);

    const addPageRouteChange = useMemo(() => (subs) => {
        if (!window || !subs || !success) {
            return;
        }

        const currentSessionSuccess = window.sessionStorage.getItem(SUCCESS_ID);
        if (currentSessionSuccess !== JSON.stringify(success)) {
            window.sessionStorage.setItem(SUCCESS_ID, JSON.stringify(success));

            const selectedProductsProcessed = subs?.reduce((items, product) => {
                const productProcessed = {
                    name: product.tier,
                    price: product.price,
                    quantity: 1,
                    variant: product.tier.match(/\d+/)[0],
                };

                return items.concat(productProcessed);
            }, []);

            const revenue = selectedProductsProcessed?.map((product) => product.price).reduce((sum, price) => sum += price);

            const dataLayerObj = {
                'event': 'checkout',
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': success,
                            'affiliation': 'MetaDefender webshop',
                            'revenue': revenue
                        },
                        'products': selectedProductsProcessed
                    }
                }
            };

            GtmService.initialize({
                event: 'checkout',
                ecommerce: dataLayerObj.ecommerce
            });

            logService.log('Sending to GTM Success: ' + JSON.stringify(dataLayerObj));
        }

    }, [success]);

    const storeDom = useMemo(() => {
        if (!success) {
            return <div className='store'>
                <Store
                    currency={currency}
                    currencySymbol={currencySymbol}
                    products={products}
                    StoreService={StoreService}
                    paymentPending={isPaymentPending}
                    paymentCompleted={isPaymentCompleted}
                    hasSubscriptions={hasSubscriptions}
                    subscriptions={subscriptions}
                    expirationDate={expirationDate}
                    user={userData}
                    redirect={Authenticator.authRedirect}
                    notify={notify}
                    emailValidator={emailValidator}
                    countryValidator={countryValidator}
                    isOrganization={isOrganization}
                    defaultPlanType={planType}
                    faq={faqContent}
                />
            </div>;
        }

        return <ThankYou
            user={userData}
            subscriptions={subscriptionsHistory}
            transactionId={success}
            addPageRouteChange={addPageRouteChange}
            className='thankYou'
        />;

    }, [
        userData,
        success,
        currency,
        currencySymbol,
        products,
        StoreService,
        isPaymentPending,
        isPaymentCompleted,
        hasSubscriptions,
        subscriptions,
        expirationDate,
        userData,
        Authenticator.authRedirect,
        notify,
        emailValidator,
        countryValidator,
        planType,
        faqContent,
        subscriptionsHistory
    ]);

    return (
        <>
            <Helmet>
                <script src='https://js.stripe.com/v3/'></script>
            </Helmet>
            <Layout pageTitle='Store' className='storeLayout' pageDescription={'MetaDefender Cloud Store selling 4 different MetaDefender Cloud APIs that each enable different security flows'}>
                {storeDom}
            </Layout>
        </>
    );
};

StoreTemplate.propTypes = {
    pageContext: PropTypes.object.isRequired
};

export default StoreTemplate;
